<template>
  <div class="demo-tile">
    <div class="tile-header">
      <div class="row">
        <div class="col-8">
          <h4>{{ data.displayTitle }}</h4>
          <h6>{{ data.subtitle }}</h6>
        </div>
        <div class="col-4 text-center">
          <img
            v-if="data.logo"
            :src="require('@/assets/' + data.logo)"
            :alt="data.title"
          />
        </div>
        <div class="col-12">
          <div class="meta-container">
            <span v-if="data.industry" class="meta meta-industry">{{
              data.industry[0]
            }}</span>
            <span v-if="data.category" class="meta meta-category">{{
              data.category[0]
            }}</span>
            <span v-if="data.platform" class="meta meta-platform">{{
              data.platform[0]
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tile-content">
      <p>{{ data.overview }}</p>
      <button class="btn btn-demo" @click="triggerDemoModal()">
        More info
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DemoCard",
  props: {
    data: Object,
  },
  methods: {
    triggerDemoModal() {
      this.$root.activeDemo = this.data;
      this.$root.showDemoModal();
      this.$gtag.event("Viewed demo overview", {
        event_category: "Demo overview",
        event_label: this.$root.activeDemo.title,
      });
    },
  },
};
</script>