<template>
    <main class="main">
        <section class="golden-ticket">
            <div class="container container-narrow">
                <div class="row">
                    <div class="col">
                        <h3 class="spinner-title">Enter your golden ticket number</h3>
                        <img class="golden-ticket" src="@/assets/ticket.svg"/>
                    </div>
                </div>
                <div class="row spinner-buttons">
                    <router-link class="btn btn-primary" to="/demos" >Explore Playground</router-link>
                    <router-link class="btn btn-ghost" to="/demos">Back</router-link>
                </div>
            </div>
        </section>
        <div class="bg-container">
            <div class="blob blob-5">
                <img src="@/assets/blob2.svg" />
            </div>
            <div class="blob blob-3">
                <img src="@/assets/blob3.svg" />
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        name: "GoldenTicket"
    }
</script>

<style scoped>

</style>