<template>
  <div
    class="modal modal-exit"
    :class="{ toggled: this.$root.exitModalActive }"
  >
    <div class="modal-dialog modal-dialog-small">
      <div class="container">
        <div @click="closeModal()" class="close-button">
          <img src="@/assets/close.svg" alt="" />
        </div>
        <div class="row">
          <div class="col">
            <h2>Exit through the gift shop</h2>
            <p>
              Let's stay connected! So before you go, please have a look at
              these resources.
            </p>
            <hr />
            <h4 class="exit-heading">Get in touch</h4>
            <p>
              Get in touch with us and let us take you through the possibilities
              in modern data analytics.
            </p>
            <a
              href="https://mantelgroup.com.au/contact"
              target="_blank"
              class="btn btn-primary"
              >Get in touch</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExitModal",
  props: {
    data: Object,
  },
  methods: {
    closeModal() {
      this.$root.hideExitModal();
    },
  },
};
</script>
