

export const demos = [{
        id: "4",
        title: "Hospital waitlist forecaster",
        displayTitle: "Hospital waitlist forecaster",
        slug: "hospital-waitlist-forecaster", // Don't change this
        subtitle: "Forecasting elective surgery waitlist scenarios",
        tags: ["qlik", "health"],
        platform: ["Qlik"],
        industry: ["health"],
        category: ["Dashboard"],
        overview: "This simple tool offers a conceptual look at how hospitals can model their forecasted elective surgery demand, and workshop actions to mitigate the business and patient impacts.",
        description: "<p>As we continue to partner with hospitals across Australia, we increasingly recognise the additional challenges of managing elective surgery hospital waitlists amidst the threat of further outbreaks of the COVID pandemic. </p><p>Which poses the question:</p><p><em>“What are our options in addressing this growing demand, and how do we meet our current access targets whilst delivering quality care and improved patient experience?”</em></p><p>To support hospital providers and front-line clinical staff in working to answer this question, Mantel Group has teamed up with Green Cup Consulting to develop a simple Hospital Waitlist Scenario Forecaster.</p><p>This simple tool offers a conceptual look at how hospitals can model their forecast demand to determine what actions they may need to take on the supply side to get back on track, specifically helping surface questions around:</p><ul><li>What increase in overall theatre capacity is required?</li><li>What options do we have for improving patient prioritisation?</li><li>How might we improve staff utilisation and rostering management?</li><li>What could be the right costing model to apply to our shifting operations model?</li><li>How can we measure genuine demand and improve the quality of our referrals?</li><li>What alternative options are at our disposal to mitigate the impact of surging demand?</li><li>How much of an impact on the supply side will each of these options provide and at what relative cost?</li></ul><p>To view a short interactive demo facilitated by Mantel Group Data Partner Brett Thebault, Health Lead Samantha Scott, and Green Cup Consulting Director Sean Lowry, click below.</p><a class='btn btn-secondary' href='https://www.youtube.com/watch?v=8i1_PYyXhGA&feature=youtu.be' target='_blank'>Watch video</a>",
        logo: "logo-waitlist-calculator.svg",
        coverImage: "demo-waitlist-calculator.png",
        demoUrl: "https://qap.public.aginic.com/extensions/waitlist-forecaster/index.html#/",
        videoUrl: "https://www.youtube.com/watch?v=8i1_PYyXhGA&feature=youtu.be"
    },  
    {
        id: "5",
        title: "Operations Quality Register",
        displayTitle: "Operations Quality Register",
        slug: "operations-quality-register", // Don't change this
        subtitle: "Tracking excellence in care",
        tags: ["qlik", "health"],
        platform: ["Qlik"],
        industry: ["health"],
        category: ["Dashboard"],
        overview: "This demo provides cancer surgeons and hospital managers the ability to easily compare their performance with the performance of other clinicians and hospitals.",
        description: "<p>The solution is presented in Qlik and can easily be embedded into any portal or website.</p><p>This Qlik report provides Cancer surgeons and hospital managers the ability to easily compare their performance with the performance of other clinicians and hospitals. This report also shows statistical positive and negative outliers allowing users to see if they fall into either of these categories.</p><p>This report also allows the clinicians see the results in the registry and how it compares with the National Bowel Cancer Screening Program and see if the values are region based or if only specific Statistical Areas are seeing high/low rates of colorectal cancer.</p>",
        logo: "logo-oqr.svg",
        coverImage: "demo-oqr.png",
        demoUrl: "https://qap.public.aginic.com/extensions/operations-quality-registry/index.html#/"
    },   
    {
        id: "1",
        title: "Sunnyside Aged Care",
        displayTitle: "Sunnyside Aged Care",
        slug: "sunnyside-aged-care", // Don't change this
        subtitle: "SQF & Operational Reporting",
        tags: ["power bi", "health"],
        platform: ["PowerBI"],
        industry: ["health"],
        category: ["Dashboard"],
        overview: "Enables Executives, State Managers & Facility Managers to make more informed, data-driven decisions regarding the operational performance of facilities, as well as provide visibility over their SQF risk profile.",
        description: "<p>The Aged Care industry is currently going through an unprecedented time of rapid change and Aged Care providers are adapting through innovating. At Mantel Group, we've been working closely with providers to help manage this change through data analytics.</p><p>This demo addresses 2 high priority needs that have emerged in the industry:</p><ul><li>Helping Facility Managers on the ground making more informed decisions through data-driven tools. This encompasses both responding to the new 8 Single Quality Framework Standards as a result of the recent royal commission, as well as understanding operational performance day-to-day.</li><li>Providing the Executive layer greater oversight into the drivers of this operational performance, which is essential in maximising the quality of care delivered to consumers in a financially sustainable way.</li></ul><p>Now more than ever, given the COVID-19 happenings, it is crucial for Aged Care providers to understand the quality of care they are providing to the most vulnerable members of our society whilst maintaining the efficient running of the organisation.</p>",
        logo: "logo-sunnyside.svg",
        coverImage: "demo-sunnyside.png",
        demoUrl: "https://app.powerbi.com/view?r=eyJrIjoiOGQ4ODMzZGUtMDFmMi00MjBlLTkwYWEtYWY0NWUxOTk5ZWIxIiwidCI6IjdhN2U1NDFjLTA1N2YtNDUxNi04ZTcyLTI1ODk1OTA3ZGI3NiIsImMiOjEwfQ%3D%3D"
    },  
    {
        id: "9",
        title: "Health Analytics | Thought Leadership | Webinar",
        displayTitle: "Health Analytics | Thought Leadership | Webinar",
        slug: "health-analytics-webinar", // Don't change this
        subtitle: "Art of the possible in healthcare analytics",
        tags: ["health"],
        platform: ["Webinar"],
        industry: ["health"],
        category: ["Video"],
        overview: "Catch up with Mark Alexander, Samantha Scott, and Rob Mackay, as well as Green Cup Consulting Director Sean Lowry (FACHSM) as they highlight the art of the possible in healthcare analytics.",
        description: "<p>Catch up with Mark Alexander, Samantha Scott, and Rob Mackay, as well as Green Cup Consulting Director Sean Lowry (FACHSM) as they highlight the art of the possible in healthcare analytics.</p><p>The team share their perspective on how data analytics can help solve strategic, operational, and clinical challenges in healthcare today in partnership with the Australasian Institute of Digital Health (AIDH).</p><p>Or to get your hands on some of the demos presented during the session, continue to explore our playground demo site.</p>",
        coverImage: "demo-aidh.png",
        demoUrl: false,
        videoUrl: "https://tv.digitalhealth.org.au/Mediasite/Play/16b4d4cfa66f4d65b30218665ceddc911d"
    },  
    {
        id: "8",
        title: "School and Individual Student Summary",
        displayTitle: "School and Individual Student Summary",
        slug: "school-and-individual-student-summary", // Don't change this
        subtitle: "Bringing data together to enable greater visibility and enquiry of a student and class.",
        tags: ["student","teacher","principal","assessment","attendance"],
        platform: ["PowerBI"],
        industry: ["education"],
        category: ["Dashboard"],
        overview: "The dashboard enables teachers and principals access to attendance and assessment data for their currently enrolled students on a single page.",
        description: "<p>Student List displays assessment and attendance data for currently enrolled students either viewed on a class level (list of students)  or an individual student level. The report demonstrates how separate systems of data entry can be linked together to create a class or student profile on a single page.</p><p>This enables teachers and principals to streamline the manual effort required to collate information and reinvest the additional time to focus on supporting students through data driven decisions.</p><p>At Mantel Group, we have worked with the NT Government to enable all school based users greater visibility of their student’s data incorporating a wide set of data including (not limited to):</p><ul><li>School assessment (NAPLAN, PAT by ACER, A-E, English as a Second Language, etc)</li><li>School Attendance</li><li>Enrolment history</li><li>Suspension and behaviour</li><li>School and Student demographic</li></ul>",
        logo: "logo-student.svg",
        coverImage: "demo-student.png",
        demoUrl: "https://app.powerbi.com/view?r=eyJrIjoiOGNkNzcyYWYtNzEwOS00MzA1LTljZjAtZGYyMThhZDU3YWEzIiwidCI6IjdhN2U1NDFjLTA1N2YtNDUxNi04ZTcyLTI1ODk1OTA3ZGI3NiIsImMiOjEwfQ%3D%3D",
        videoUrl: ""
    }, 
    {
        id: "2",
        title: "FlyHigh Retail Stores",
        displayTitle: "FlyHigh Retail Stores",
        slug: "flyhigh-retail-stores", // Don't change this
        subtitle: "A one stop shop for a manager to manage their stores. ",
        tags: ["power bi", "retail"],
        platform: ["PowerBI"],
        industry: ["retail"],
        category: ["Dashboard"],
        overview: "Enables managers to easily see a snapshot of how their stores are performing and what areas can be improved.",
        description: "<p>With data relating to different areas of a business being stored in different formats, it can be hard to present it all on one page and the information can be easily interpreted. In this report we brought together 12 different metrics that were all stored in different formats and have presented them on a single report.</p><p>The real benefit of this report is that it can be scaled to include more metrics and for this demonstration, all the data manipulations have been done in Power BI. We have also manipulated the data to attempt to place all the metrics on a single comparable scale to make it easier to compare and contrast the different metrics.</p>",
        logo: "logo-retail.svg",
        coverImage: "demo-retail.png",
        demoUrl: "https://app.powerbi.com/view?r=eyJrIjoiZDlkODg0ZjItODZkNC00YzAzLWE0ZWQtN2ExZDYyM2U0OTNmIiwidCI6IjdhN2U1NDFjLTA1N2YtNDUxNi04ZTcyLTI1ODk1OTA3ZGI3NiIsImMiOjEwfQ%3D%3D&pageName=ReportSectiond1087222ab3282c54bb8"
    },
    {
        id: "9",
        title: "School and System Level Assessment Tracking",
        displayTitle: "School and System Level Assessment Tracking",
        slug: "school-and-system-level-assessment-tracking", // Don't change this
        subtitle: "Minimise the time required to see the progress of assessment completion across multiple schools.",
        tags: ["student","teacher","principal","assessment","attendance"],
        platform: ["PowerBI"],
        industry: ["education"],
        category: ["Dashboard"],
        overview: "A dashboard which enables single and multiple school users to track the progress of student assessment completion within a single page.",
        description: "<p>This dashboard displays a representation of student assessment completion tracking which enables teachers and schools the ability to provide more efficient student care and monitoring.</p><p>The report enables users to see a quick live snapshot of assessment completion progress, which promotes more effective communication between corporate and school based users. This shared platform assists in targeting students who may not have completed the assessment.</p><p>There are two pages which represent what data a corporate user (multiple schools) would see versus a school based user (single school).</p><p>At Mantel Group, we have worked with the NT Government to better visualise their early childhood literacy assessment participation for students primarily in their first year of full time schooling. The platform and dashboard can be accessed by both school and corporate users and this has resulted in greater opportunity for conversation and follow up between both user groups.</p>",
        logo: "logo-school.svg",
        coverImage: "demo-school.png",
        demoUrl: "https://app.powerbi.com/view?r=eyJrIjoiMmNmOWQxNWQtY2E1My00MGE4LWE1ZmMtYjU2MTdlZTE1ZDZiIiwidCI6IjdhN2U1NDFjLTA1N2YtNDUxNi04ZTcyLTI1ODk1OTA3ZGI3NiIsImMiOjEwfQ%3D%3D",
        videoUrl: ""
    }, 
    {
        id: "6",
        title: "Health Hub",
        displayTitle: "Health Hub",
        slug: "health-hub", // Don't change this
        subtitle: "Demographic health data explorer",
        tags: ["qlik", "health"],
        platform: ["Qlik"],
        industry: ["health"],
        category: ["Dashboard"],
        overview: "Health Hub displays access to care related data overlaid with demographic data. The report shows how demographics change across the state of Victoria, including the access to care that they have.",
        description: "<p>This demo is designed to be used by the general public and Hospital Health Service managers. This demo is designed to assist with monitoring and reporting indicators of the health, wellbeing and safety of the Aboriginal Victorian population through interactive, visually compelling data using time-based and placed-based analysis.</p><p>The hope is this will promote and facilitate greater use of data by making it more available, accessible, useable, and consumable in a more timely manner to Aboriginal communities.</p>",
        logo: "logo-health-hub.svg",
        coverImage: "demo-health-hub.png",
        demoUrl: ""
    },  
    {
        id: "3",
        title: "Browsr",
        displayTitle: "Browsr",
        slug: "browsr", // Don't change this
        subtitle: "Full contextual search over thousands of PDFs",
        tags: ["elastic", "health"],
        platform: ["elasticsearch"],
        industry: ["education"],
        category: ["Full-text search"],
        overview: "Enables contextual search over Parliamentary Hansard documents to identify and export questions on notice. This tool automates a previously manual process - taking staff a matter of hours to complete, as opposed to an entire week.",
        description: "<p>Browsr is designed to assist the Parliamentary and Estimates team in efficiently completing the Senate Estimates engagement process. The tool provides full text search over thousands of Hansard documents, enabling;</p><ul><li>more efficient topic analysis, upload and allocation within the engagement process</li><li>staff to search across multiple sources to identify likely topics to inform briefings in preparation for upcoming senate estimates hearings</li></ul><p>Drop-down filters allow staff to narrow their search to a particular hearing. Specific keywords are identified throughout the PDF documents and then displayed to the user along with a page number reference and preceding and proceeding sentences to give context. Once multiple QoNs (questions on notice) have been shortlisted, these can be exported to CSV and uploaded into another system to complete the engagement process.</p>",
        logo: "logo-browsr.svg",
        coverImage: "demo-browsr.png",
        demoUrl: "https://es-browsr-dot-steel-spark-226123.uc.r.appspot.com"
    }
]
