<template>
    <main class="main">
        <section class="exit">
            <div class="container container-narrow">
                <div class="row">
                    <div class="col">
                        <h2 class="exit-h2" >Let's stay in touch</h2>
                        <h4 class="exit-heading" >Boombox</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc facilisis congue malesuada. Quisque vehicula vel ante id facilisis. Nam non ipsum lectus. Vestibulum vel imperdiet felis, et dictum quam.</p>
                        <h4 class="exit-heading" >Newsletter</h4>
                            <button class="btn btn-secondary">Email address</button>
                        <h4 class="exit-heading" >Schedule a call</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc facilisis congue malesuada. Quisque vehicula vel ante id facilisis. Nam non ipsum lectus. Vestibulum vel imperdiet felis, et dictum quam.</p>
                        <button class="btn btn-primary">Let's get started</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="bg-container">
            <div class="blob blob-5">
                <img src="@/assets/blob2.svg" />
            </div>
            <div class="blob blob-6">
                <img src="@/assets/blob6.svg" />
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        name: "Exit"
    }
</script>

<style scoped>

</style>