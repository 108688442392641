import Vue from "vue";
import VueRouter from "vue-router";

// Import views
import Landing from "./views/Landing.vue";
import GoldenTicket from "./views/GoldenTicket.vue";
import Main from "./views/Main.vue";
import WheelOfFortune from "./views/WheelOfFortune.vue";
import Exit from "./views/Exit.vue";

export default new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  // Define all routes
  routes: [
    {
      path: "/",
      name: "Landing",
      component: Landing
    },
    {
      path: "/demos",
      name: "Main",
      component: Main
    },
    {
      path: "/golden-ticket",
      name: "GoldenTicket",
      component: GoldenTicket
    },
    {
      path: "/wheel-of-fortune",
      name: "WheelOfFortune",
      component: WheelOfFortune
    },
    {
      path: "/exit",
      name: "Exit",
      component: Exit
    }
  ]
});

