import Vue from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";

// Router
import VueRouter from "vue-router";
import router from "./router";

Vue.use(VueRouter);

Vue.use(VueGtag, {
  config: { id: "G-FWDZFZECKB" }
});

Vue.config.productionTip = false

new Vue({
  data: function () {
    return {
      demoModalActive: false,
      followUpModalActive: false,
      exitModalActive: false,
      activeDemo: {}
    }
  },
  methods: {
    showDemoModal(): void {
      this.demoModalActive = true;
    },
    hideDemoModal(): void {
      this.demoModalActive = false;
    },
    showFollowUpModal(): void {
      this.followUpModalActive = true;
    },
    hideFollowUpModal(): void {
      this.followUpModalActive = false;
    },
    showExitModal(): void {
      this.exitModalActive = true;
    },
    hideExitModal(): void {
      this.exitModalActive = false;
    }
  },

  watch: {
    $route(to, from) {
      // Close modals on navigation if they are open
      if (this.demoModalActive) {
        this.hideDemoModal();
      }
      if(this.followUpModalActive) {
        this.hideFollowUpModal();
      }
      if(this.exitModalActive) {
        this.hideExitModal();
      }
    },
  },
  router,
  render: h => h(App),
}).$mount('#app')
