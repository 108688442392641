<template>
  <div
    class="modal modal-demo"
    :class="{ toggled: this.$root.demoModalActive }"
  >
    <div class="modal-dialog">
      <div class="container">
        <div class="close-button" @click="closeModal()">
          <img src="@/assets/close.svg" alt="" />
        </div>
        <div class="row">
          <div class="col-sm-6 order-2 order-sm-1">
            <h2>{{ data.displayTitle }}</h2>
            <h3>{{ data.subtitle }}</h3>
            <div class="meta-container">
              <span v-if="data.industry" class="meta meta-industry">{{
                data.industry[0]
              }}</span>
              <span v-if="data.category" class="meta meta-category">{{
                data.category[0]
              }}</span>
              <span v-if="data.platform" class="meta meta-platform">{{
                data.platform[0]
              }}</span>
            </div>

            <div class="scrollbox">
              <span class="description" v-html="data.description"></span>
            </div>
            <a
              v-if="data.demoUrl"
              @click="openDemo"
              :href="data.demoUrl"
              target="_blank"
              class="btn btn-primary"
              >Start demo</a
            >
            <button v-else-if="data.demoUrl === ''" class="btn btn-disabled">Coming soon</button>
            <a
              v-if="data.videoUrl"
              :href="data.videoUrl"
              target="_blank"
              class="btn btn-secondary"
              >Watch video</a
            >
            <button class="btn btn-ghost" @click="closeModal()">Back</button>
          </div>
          <div
            class="col-sm-6 order-1 order-sm-2 my-auto"
            v-if="data.coverImage"
          >
            <img
              :src="require('@/assets/' + data.coverImage)"
              class="demo-image"
              :alt="data.title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DemoModal",
  props: {
    data: Object,
  },
  methods: {
    closeModal() {
      this.$root.hideDemoModal();
    },
    openDemo() {
      this.closeModal();
      this.$gtag.event("Viewed demo", {
        event_category: "Demo",
        event_label: this.$root.activeDemo.title,
      });
      this.$root.showFollowUpModal();
    },
  },
};
</script>