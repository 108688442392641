<template>
  <div
    class="modal modal-follow-up"
    :class="{ toggled: this.$root.followUpModalActive }"
  >
    <div class="modal-dialog modal-dialog-small">
      <div class="container">
        <div @click="closeModal()" class="close-button">
          <img src="@/assets/close.svg" alt="" />
        </div>
        <!-- <div class="next-button">
          <img src="@/assets/next-button.svg" />
        </div> -->
        <div class="row">
          <div class="col">
            <h2>Did you enjoy the demo?</h2>
            <p class="py-4">
              This is just a small sample of what we can achieve with data. If
              you have any questions or comments, please don’t hesitate to click
              the ‘Get in touch’ button. We’d love to hear from you and get an
              idea of what problems you are trying to solve. Otherwise, feel
              free to keep exploring. There are so many more demos to try.
            </p>
            <a
              href="https://mantelgroup.com.au/contact"
              target="_blank"
              class="btn btn-primary"
              >Get in touch</a
            >
            <button class="btn btn-secondary btn-double" @click="closeModal">
              Continue exploring
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FollowUpModal",
  props: { data: Object },
  methods: {
    closeModal() {
      this.$root.hideFollowUpModal();
    },
  },
};
</script>
