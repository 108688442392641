<template>
  <main class="main">
    <section class="welcome-block">
      <div class="container container-narrow">
        <div class="row">
          <div class="col-md-6 mb-2">
            <h1>Welcome, friend.</h1>
          </div>
          <div class="col-md-6 adventure mb-5">
            <img src="@/assets/adventure.svg" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 order-2 order-md-1">
            <a href="#rides" class="btn btn-primary btn-lg">Browse demos</a>
            <!--  <router-link class="golden-ticket-link mt-4" to="/golden-ticket">I have a golden ticket</router-link> -->
          </div>
          <div class="col-md-8 order-1 order-md-2">
            <p>
              Well, you made it past the landing page, so you must be ready to
              <a href="#rides">go exploring</a>! We'll be frequently updating
              Playground with new demos.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="filter-block py-5" id="rides">
      <div class="container container-narrow">
        <div class="row">
          <div class="col">
            <h3>Demos</h3>
            <p>
              Analytics. Insights. Knowledge. Where does it all come from? How
              does it all come together? What questions need to be answered?
              And, indeed, what answers need to be questioned? Here at Mantel
              Group, we love asking questions and hunting down the answers. To
              prove this, we’ve developed a series of ‘demos’ which we hope will
              provide you with some inspiration on what can be achieved with
              data.
              <!-- You can filter the demos by category. -->
            </p>
            <!-- <p>If you’re in one of those moods where you simply can’t decide what demo you want to try, just select ‘I’m feeling lucky’, which will randomly choose one for you.</p> -->
            <div class="filters py-3">
              <!-- <button class="btn btn-secondary filter-button">Filter by use case</button>
              <button class="btn btn-secondary filter-button">Filter by category</button> -->

              <multiselect
                ref="categoriesFilter"
                :options="demoCategories"
                v-model="selectedCategories"
                :taggable="true"
                :multiple="true"
                :searchable="false"
                :close-on-select="false"
                :clear-on-select="false"
                :showLabels="false"
                :openDirection="'bottom'"
                placeholder="Filter by category"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span class="multiselect__single" v-if="values.length"
                    >{{ values.length }} selected</span
                  >
                  <span
                    class="multiselect__single"
                    v-if="!values.length && isOpen"
                    >Select</span
                  >
                </template>
              </multiselect>
              <router-link class="btn btn-random" to="/wheel-of-fortune"
                >I'm feeling lucky</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="demo-block">
      <div class="container container-narrow">
        <transition-group class="row demo-row">
          <div
            class="col-md-6 demo-item"
            v-for="item in filteredDemos"
            v-bind:key="item.id"
          >
            <DemoCard :data="item" />
          </div>
        </transition-group>
      </div>
    </section>

    <div class="bg-container">
      <div class="blob blob-5">
        <img src="@/assets/blob2.svg" />
      </div>
      <div class="blob blob-6">
        <img src="@/assets/blob6.svg" />
      </div>
      <div class="blob blob-7">
        <img src="@/assets/blob7.svg" />
      </div>
      <div class="blob blob-8">
        <img src="@/assets/blob8.svg" />
      </div>
    </div>
  </main>
</template>

<script>
import DemoCard from "@/components/DemoCard.vue";
import { demos } from "@/demoData.js";
import Multiselect from "vue-multiselect";

export default {
  name: "Main",
  components: { DemoCard, Multiselect },
  computed: {
    filteredDemos() {
      let filteredDemos = [];
      if (this.selectedCategories.length > 0) {
        filteredDemos = this.demoData.filter((x) =>
          x.category.some((g) => this.selectedCategories.includes(g))
        );
      } else {
        filteredDemos = this.demoData;
      }
      return filteredDemos;
    },
  },
  created() {
    this.initFilters();
  },
  mounted() {
    this.demoData = demos;
  },
  props: {
    data: Object,
  },
  data() {
    return {
      demoData: [],
      demoCategories: [],
      selectedCategories: [],
      demoIndustries: [],
      demoPlatforms: [],
      categoryFilterLabel: "",
    };
  },
  methods: {
    triggerFollowUpModal() {
      this.$root.activeDemo = this.data;
      this.$root.showFollowUpModal();
    },

    initFilters() {
      demos.forEach((demo) => {
        // Populate categories
        demo.category.forEach((demoCategory) => {
          if (this.demoCategories.indexOf(demoCategory) === -1)
            this.demoCategories.push(demoCategory);
        });

        // Populate industries
        demo.industry.forEach((demoIndustry) => {
          if (this.demoIndustries.indexOf(demoIndustry) === -1)
            this.demoIndustries.push(demoIndustry);
        });

        // Populate platforms
        demo.platform.forEach((demoPlatform) => {
          if (this.demoPlatforms.indexOf(demoPlatform) === -1)
            this.demoPlatforms.push(demoPlatform);
        });
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
