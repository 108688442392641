<template>
  <div id="app">
    <header class="site-header">
      <a id="logo" @click="triggerExitModal()">
        <img src="@/assets/app-logo.svg" />
      </a>
    </header>
    <transition name="page" mode="out-in">
      <router-view></router-view>
    </transition>
    <DemoModal :data="this.$root.activeDemo" />
    <FollowUpModal :data="this.$root.activeDemo" />
    <ExitModal />
  </div>
</template>

<script>
import DemoModal from "./components/DemoModal.vue";
import FollowUpModal from "./components/FollowUpModal.vue";
import ExitModal from "./components/ExitModal.vue";
import { demos } from "@/demoData.js";

export default {
  name: "App",
  components: { DemoModal, FollowUpModal, ExitModal },
  props: {
    data: Object,
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const demo = urlParams.get("demo");
    // User is requesting a demo
    if (demo) {
      const requestedDemo = demos.filter((obj) => {
        return obj.slug === demo;
      });
      if (requestedDemo[0]) {
        this.$root.activeDemo = requestedDemo[0];
        this.$root.showDemoModal();
      }
    }
  },
  methods: {
    triggerExitModal() {
      this.$root.showExitModal();
    },
  },
};
</script>
<style lang="scss">
@import "./src/scss/style.scss";
</style>
