<template>
  <main class="landing">
    <div class="landing-container">
      <div class="row">
        <div class="col-12">
          <h1 class="h2">Playground</h1>
          <span class="subtitle mb-4">[pleɪɡraʊnd] noun</span>
          <p class="description text-left mb-4">
            Welcome to our playground! A curated space filled with creative data
            solutions that showcase new technologies and inform action for
            change. We strive to stay ahead of industry tends and love sharing
            what we learn to spark positive change in business.
          </p>

          <router-link to="/demos" class="btn btn-primary btn-lg mb-3"
            >Explore Playground</router-link
          >
          <!--  <router-link to="/golden-ticket" class="golden-ticket-link">I have a golden ticket</router-link> -->
        </div>
      </div>
    </div>
    <div class="bg-container">
      <div class="blob blob-1">
        <img src="@/assets/blob1.svg" />
      </div>
      <div class="blob blob-2">
        <img src="@/assets/blob2.svg" />
      </div>
      <div class="blob blob-3">
        <img src="@/assets/blob3.svg" />
      </div>
      <div class="blob blob-4">
        <img src="@/assets/blob4.svg" />
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Landing",
};
</script>