<template>
  <main class="main">
    <section class="wheel-of-fortune">
      <div class="container container-narrow">
        <div class="row">
          <div class="col">
            <h3 class="spinner-title">Wheel of Fortune</h3>
          </div>
        </div>
        <div class="row py-5">
          <div class="col-md-6 spinner-wrap">
            <div class="spinner-container">
              <div class="spinner">
                <img ref="wheel" src="@/assets/spinner.svg" />
              </div>
            </div>
          </div>
          <div class="col-md-6 my-auto">
            <div class="wheel-info-tile" :class="{ hidden: initiated }">
              Click the 'Spin wheel' button to see what's in store for you. Try
              it as many times as you like!
            </div>
            <DemoCard :class="{ toggled: cardVisible }" :data="currentCard" />
          </div>
        </div>
        <div class="row spinner-buttons">
          <div class="col-12 text-center">
            <button @click="triggerSpin" class="btn btn-primary">
              Spin wheel
            </button>
            <router-link class="btn btn-ghost" to="/demos"
              >Back to Playground</router-link
            >
          </div>
        </div>
      </div>
    </section>
    <div class="bg-container">
      <div class="blob blob-5">
        <img src="@/assets/blob2.svg" />
      </div>
      <div class="blob blob-3">
        <img src="@/assets/blob3.svg" />
      </div>
    </div>
  </main>
</template>

<script>
import DemoCard from "@/components/DemoCard.vue";
import { demos } from "@/demoData.js";

export default {
  name: "WheelOfFortune",
  components: { DemoCard },
  computed: {},
  props: {
    data: Object,
  },
  data() {
    return {
      randomisedCards: [],
      currentCard: {},
      initiated: false,
      wheelRotation: 0,
      cardVisible: false,
      isSpinning: false,
      spinDuration: 2000,
    };
  },
  mounted() {
    this.randomiseCards();
    this.currentCard = this.randomisedCards[0];
  },
  methods: {
    randomiseCards() {
      const newArray = demos.slice();
      for (let i = newArray.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[rand]] = [newArray[rand], newArray[i]];
      }
      // Check that we don't roll the same once twice in a row
      if (newArray[0] === this.currentCard) {
        this.randomiseCards();
      } else {
        this.randomisedCards = newArray;
      }
    },

    triggerSpin() {
      if (!this.initiated) {
        this.initiated = true;
      }
      // Check if it's spinning
      if (!this.isSpinning) {
        // Hide card
        this.hideCard();

        // Spin the wheel
        this.spinWheel();

        // Show card on delay
        setTimeout(() => {
          this.changeCard();
          this.showCard();

          this.isSpinning = false;
        }, this.spinDuration);
      }
      this.$gtag.event("Spinned wheel", {
        event_category: "Wheel of fortune",
      });
    },

    changeCard() {
      // Set the current card
      this.currentCard = this.randomisedCards[0];

      // Shift array or create a new random order
      if (this.randomisedCards.length > 1) {
        this.randomisedCards.shift();
      } else {
        this.randomiseCards();
      }
    },

    showCard() {
      this.cardVisible = true;
    },

    hideCard() {
      this.cardVisible = false;
    },

    spinWheel() {
      this.isSpinning = true;

      // Create a random rotation 720deg (base) + 0-720deg
      let randomRotation = Math.floor(Math.random() * Math.floor(720)) + 720;

      // Apply the calculated rotation to the base rotaiton
      this.wheelRotation += randomRotation;

      // Change the element style
      let spinningWheel = this.$refs.wheel;
      spinningWheel.style.transform = "rotate(" + this.wheelRotation + "deg)";
    },
  },
};
</script>